import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './components/home/home.component';
import {ErrorPageComponent} from './components/error-page/error-page.component';
import {PageNotFoundComponent} from "@core/components/page-not-found/page-not-found.component";
import {LoadingPageComponent} from './components/loading-page/loading-page.component';
import {NgxServiceWorkerModule} from "@juulsgaard/ngx-service-worker";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressBarModule} from "@angular/material/progress-bar";


@NgModule({
  declarations: [
    HomeComponent,
    ErrorPageComponent,
    PageNotFoundComponent,
    LoadingPageComponent
  ],
  exports: [
    LoadingPageComponent,
    ErrorPageComponent
  ],
  imports: [
    MatSnackBarModule,
    CommonModule,
    MatButtonModule,
    MatProgressBarModule,
    NgxServiceWorkerModule
  ]
})
export class CoreModule { }
